import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import tenantStoreModule from '../tenantStoreModule';

export default function useTenantModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'tenant';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, tenantStoreModule);
  }
  const { t } = useI18nUtils();
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const isVmarket = process.env.VUE_APP_PROVIDER === 'vmarket';

  const toastification = toast();
  const isLoading = ref(false);
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const itemId = computed(() => (props.item && props.item.id ? props.item.id : null));
  const fetchDetailData = () => {
    if (itemId.value) {
      isLoading.value = true;
      store.dispatch('tenant/getTenantDetail', itemId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };
  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const formData = new FormData();
    formData.append('individual', itemLocal.value.individual);
    formData.append('name', itemLocal.value.name || '');
    formData.append('phone', itemLocal.value.phone || '');
    if (itemLocal.value.email && itemLocal.value.email.length > 0) {
      formData.append('email', itemLocal.value.email);
    }

    formData.append('idNumber', itemLocal.value.idNumber || '');
    formData.append('placeOfIssue', itemLocal.value.placeOfIssue || '');
    formData.append('address', itemLocal.value.address || '');
    formData.append('foreign', itemLocal.value.foreign || false);
    formData.append('passportNumber', itemLocal.value.passportNumber || '');
    formData.append('passportType', itemLocal.value.passportType || '');
    formData.append('businessCode', itemLocal.value.businessCode || '');
    formData.append('note', itemLocal.value.note || '');
    formData.append('job', itemLocal.value.job || '');
    formData.append('workplace', itemLocal.value.workplace || '');
    formData.append('contactPersonName', itemLocal.value.contactPersonName || '');
    formData.append('contactPersonPhone', itemLocal.value.contactPersonPhone || '');
    formData.append('fingerPrintCode', itemLocal.value.fingerPrintCode || '');
    formData.append('bankAccountNumber', itemLocal.value.bankAccountNumber || '');
    formData.append('bankAccountName', itemLocal.value.bankAccountName || '');
    formData.append('businessDomain', itemLocal.value.businessDomain || '');
    formData.append('currentAddress', itemLocal.value.currentAddress || '');

    if (itemLocal.value.province && itemLocal.value.province.id > 0) {
      formData.append('provinceId', itemLocal.value.province.id);
    }
    if (itemLocal.value.district && itemLocal.value.district.id > 0) {
      formData.append('districtId', itemLocal.value.district.id);
    }
    if (itemLocal.value.ward && itemLocal.value.ward.id > 0) {
      formData.append('wardId', itemLocal.value.ward.id);
    }
    if (itemLocal.value.country && itemLocal.value.country.id > 0) {
      formData.append('nationalityId', itemLocal.value.country.id);
    }
    if (itemLocal.value.gender) {
      formData.append('gender', itemLocal.value.gender.id);
    }

    const birthday = parseDateToString(itemLocal.value.birthday);
    if (birthday) {
      formData.append('birthday', birthday);
    }

    const issueDate = parseDateToString(itemLocal.value.issueDate);
    if (issueDate) {
      formData.append('issueDate', issueDate);
    }

    const dateOfExpiry = parseDateToString(itemLocal.value.dateOfExpiry);
    if (birthday) {
      formData.append('dateOfExpiry', dateOfExpiry);
    }

    // avatar
    if (itemLocal.value.avatarFile) {
      formData.append('avatar', itemLocal.value.avatarFile);
    }
    if (itemLocal.value.idNumberFrontImageFile) {
      formData.append('idNumberFrontImage', itemLocal.value.idNumberFrontImageFile);
    }
    if (itemLocal.value.idNumberBackImageFile) {
      formData.append('idNumberBackImage', itemLocal.value.idNumberBackImageFile);
    }
    if (itemLocal.value.passportImageFile) {
      formData.append('passportImage', itemLocal.value.passportImageFile);
    }
    if (itemLocal.value.certificateOfBusinessRegistrationImageFile) {
      formData.append('certificateOfBusinessRegistrationImage', itemLocal.value.certificateOfBusinessRegistrationImageFile);
    }
    if (itemLocal.value.vehicles && itemLocal.value.vehicles.length > 0) {
      formData.append('vehicles', JSON.stringify(itemLocal.value.vehicles.map(vehicle => {
        let temp = {
          typeId: vehicle.type.value,
          name: vehicle.name,
          licensePlate: vehicle.licensePlate,

        };
        if (vehicle.id && vehicle.id > 0) {
          temp = {
            ...temp,
            id: vehicle.id,
          };
        }
        return temp;
      })));
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('tenant/updateTenant', { data: formData, id: itemLocal.value.id })
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('tenant/createTenant', formData)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const addVehicle = () => {
    if (itemLocal.value.vehicles) {
      itemLocal.value.vehicles.push({
        id: 0,
        type: null,
        licensePlate: '',
        name: '',
      });
    } else {
      itemLocal.value.vehicles = [{
        id: 0,
        type: null,
        licensePlate: '',
        name: '',
      }];
    }
  };

  const removeVehicle = index => {
    itemLocal.value.vehicles.splice(index, 1);
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    addVehicle,
    removeVehicle,
    isTingTong,
    isVmarket,
    t,
    isLoading,
    onOpen,
  };
}
